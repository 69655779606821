import { Link } from "@inertiajs/react";
import Column from "../Grid/Column";
import Container from "../Grid/Container";
import Row from "../Grid/Row";

const SearchEngine3D = ({ additionalButtons = false } ) => {
    return (
        <>
            {additionalButtons &&
                <Container>
                    <Row>
                        <Column>
                            <h2 className="text-h3 leading-h3 text-primary font-extrabold mb-8 text-center uppercase">Znajdź swoje wymarzone mieszkanie</h2>
                            <div className="flex flex-wrap justify-center items-center gap-x-6 mb-8">
                                <button type="button" className={`maxMd:w-full maxMd:mb-6 button-primary border-primary border-[2px]`}>
                                    Wyszukiwarka 3D
                                </button>
                                <Link href={route('frontend.apartments', { variation: '2d'})} className={`maxMd:w-full  button-outline border-primary border-[2px]`}>
                                    Wyszukiwarka filtry
                                </Link>
                            </div>
                        </Column>
                    </Row>
                </Container>
            }
            <section className="w-full h-screen mb-24">
                <iframe
                    src="https://allinone.prod.resimo.io/monday/kusocinskiego/#/?app=jeff&lang=pl"
                    allow="fullscreen"
                    className="allinone-iframe w-full h-full"
                ></iframe>
            </section>
        </>
    )
}

export default SearchEngine3D;